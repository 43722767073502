<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: Array,
  });
</script>
<template>
  <div class="wrapper hero-container">
    <SfBanerCarousel :items class="hero-container__baner" />
  </div>
</template>
<style lang="scss">
  .hero-container {
    display: flex;
    flex-direction: row;
    height: 500px;
    position: relative;

    &__baner {
      --sf-carousel-slide-padding-x: 0;
      --sf-carousel-slide-padding-y: 0;
      --sf-carousel-button-space: 0.625rem;
      padding-top: 2rem;
    }

    .baner-carousel {
      width: 100%;
      z-index: 1;

      &__container {
        height: 100%;
        width: 100%;
      }

      &__slide {
        border-radius: 0.375rem;
        overflow: hidden;
      }

      &__link {
        width: 100%;

        picture img {
          object-fit: cover;
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  @media(max-width: 992px) {
    .hero-container {
      max-height: 50vw;

      &__baner {
        padding: 0;
      }

      .baner-carousel {
        width: 100%
      }
    }
  }
</style>
