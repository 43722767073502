<script setup>
  import { ref, computed } from 'vue';
  import { useCart } from '$storefront';

  const show = ref(false);
  const { cart } = useCart();

  const total = computed(() => cart.value?.total_gross || 0);
  const currency = computed(() => cart.value?.currency_code);
  const decimals = computed(() => cart.value?.currency_decimals);
</script>
<template>
  <div class="action-item" @click="show = true" tabindex="0" @keydown.enter="show = true">
    <IconCartHeader class="icon" />
    <SfMoney :code="currency" :decimals="decimals" :value="total" class="cart-value" />
  </div>
  <SfDrawerCart v-model:open="show" />
</template>
<style lang="scss" scoped>
  .action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: 10px;
    font-size: 24px;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    transition: var(--primary-transition);

    &:hover {
      color: var(--primary-color);
    }

    .cart-value {
      font-size: 16px;
    }
  }

  @media(max-width: 768px) {
    .action-item {
      gap: 6px;
      font-size: 22px;

      .cart-value {
        font-size: 14px;
      }
    }
  }
</style>
