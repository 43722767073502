<script setup lang="ts">
  import { route } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';

  defineProps({
    scroll: { type: Boolean, default: false, required: false },
  });

  const breakpoints = useBreakpoints();
</script>
<template>

</template>
<style scoped lang="scss">

</style>
