<script setup lang="ts">
  import { ref, type PropType, watch } from 'vue';
  import type { Breadcrumb, Section } from '$types';
  import { $redirect, route } from '@plenny/visitor';

  type ReviewsSummary = {
    1: { count: number, percent: number },
    2: { count: number, percent: number },
    3: { count: number, percent: number },
    4: { count: number, percent: number },
    5: { count: number, percent: number },
  }

  const props = defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
    sections: { type: Array as PropType<Section[]>, required: true },
    product: { type: Object, required: true },
    variant: { type: Object, required: true },
    brand: Object,
    category: Object,
    recommended: Array,
    posts: Array,
    page: Object,
    review_available: { type: Boolean, required: true },
    reviews: { type: Object, required: true },
    reviews_summary: { type: Object as PropType<ReviewsSummary>, required: true },
  });

  const variant = ref(props.variant);
  const wrapperHtmlElement = ref();

  watch(() => variant.value.slug, (value, oldValue) => {
    if (oldValue && value !== oldValue) {
      $redirect(route('web.product.show', { slug: value }), true, false);
    }
  });

  watch(() => props.variant, (value) => {
    variant.value = value;
  });
</script>
<template>
  <div class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
  </div>

  <ShopProductViewBanner :wrapper="wrapperHtmlElement" :product :variant="variant" />

  <div ref="wrapperHtmlElement" class="product-details-container">
    <ShopProductViewDetails :brand="brand" :category="category" :page="page" :product="product" v-model:variant="variant" />
  </div>

  <ShopProductViewDescription :product :recommended :reviews :sections :reviewAvailable="review_available" :reviewsSummary="reviews_summary" />
</template>
<style scoped lang="scss">

</style>

