<script setup lang="ts">
  import Section from '../../../Components/products/Section.vue';
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: String,
    brands: Array,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 3.5,
      },
      576: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 5,
      },
      992: {
        slidesPerView: 6,
      },
    },
  });

</script>

<template>
  <Section :title="header" :link="route('web.brand.index')">
    <SfBrandsCarousel :brands :parameters class="brands-carousel--frame" />
  </Section>
</template>

<style scoped lang="scss">

  @media(max-width: 576px) {
    .section-wrapper {
      padding-right: 0;
    }
  }
</style>

<style lang="scss">

  .brands-carousel--frame {
    --sf-carousel-button-space: 0;
    --sf-carousel-button-next-translateX: 50%;
    --sf-carousel-button-prev-translateX: -50%;

    .brands-carousel__container {
      border: 1px solid var(--color-grey);
      border-radius: 5px;
      padding: 1rem
    }
  }
</style>
