<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { useShared } from '@plenny/visitor';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Array, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
    products: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();
  const shared = useShared();

  const areFiltersOpen = ref(false);

  const hasProducts = computed(() => props.products.data.length > 0);
  const type = computed(() => shared.value.product_list_mode);
</script>
<template>
  <div class="products">
    <div class="products__filters">
      <SfModal v-if="!breakpoints.lg" v-model:open="areFiltersOpen">
        <SfModalHeader v-model:open="areFiltersOpen">
          {{ $t('Filtry') }}
        </SfModalHeader>
        <ShopProductsFilter class="products__modal-filters" v-bind="{ categories, brands, price, attributes, options, search }" />
      </SfModal>
      <ShopProductsFilter v-else v-bind="{ categories, brands, price, attributes, options, search }" />
    </div>
    <div class="products__content">
      <ShopProductsGridControl @onFiltersOpen="areFiltersOpen = true" />
      <div v-if="hasProducts" :class="{ 'products__wrapper--list': type === 1 }" class="products__wrapper">
        <ProductCard v-for="product in products.data" :key="product.id" :horizontal="type === 1" :product="product" class="products__product-card" />
      </div>
      <div v-else class="products__empty">
        <SfDataPlaceholder>{{ $t('Przepraszamy, nie znaleźliśmy produktów spełniających podane kryteria.') }}</SfDataPlaceholder>
      </div>
      <SfPagination :currentPage="products.current_page" :lastPage="products.last_page" :links="products.links" />
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-products-columns: 4;
    --sf-products-columns-list: 1;

    @media (max-width: 1400px) {
      --sf-products-columns: 3;
    }

    @media (max-width: 1200px) {
      --sf-products-columns: 2;
    }

    @media (max-width: 992px) {
      --sf-products-columns: 3;
    }

    @media (max-width: 768px) {
      --sf-products-columns: 2;
    }

    @media (max-width: 576px) {
      --sf-products-columns: 1;
    }
  }
</style>
<style scoped lang="scss">
  .products {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-default);
    width: 100%;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    &__filters {
      width: 23rem;
      background-color: var(--background-card);
      border-radius: var(--border-radius);
      padding: var(--padding-card);
      flex: 0 0 23rem;

      @media (max-width: 992px) {
        padding: 0;
        display: flex;
        justify-content: center;
        flex: 0 1 auto;
        width: 100%;
      }
    }

    &__modal-filters {
      flex: 1;
    }

    &__content {
      width: 100%;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(var(--sf-products-columns), minmax(180px, 1fr));
      gap: var(--gap-card);
      margin-top: var(--margin-default);
      padding: var(--padding-card);
      border-radius: var(--border-radius);
      background-color: var(--background-card);

      &--list {
        grid-template-columns: var(--sf-products-columns-list);
      }
    }

    &__empty {
      margin-top: var(--margin-default);
      padding: var(--padding-card);
      border-radius: var(--border-radius);
      background-color: var(--color-white);
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
    }
  }
</style>
