<script lang="ts" setup>
  import { trans } from '@plenny/translator';

  defineProps({
    module: { type: Object, required: true },
    breadcrumbs: { type: Array, required: true },
    sections: { type: Array, required: true },
    departments: { type: Array, required: true },
    main: { type: Object, required: true },
  });

  const days = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };
</script>
<template>
  <main class="container">
    <div class="wrapper contact-wrapper">
      <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
      <SectionRenderer :sections="sections" placement="before" />
      <section class="contact-wrapper-content">
        <div class="form-wrapper">
          <header>
            <SfHeading larger type="h1">
              {{ module.title }}
            </SfHeading>
            <div v-if="module.description_short" class="contact-description" v-html="module.description_short" />
          </header>
          <SfContactForm :departments="departments" />
        </div>
        <div class="data-wrapper">
          <section class="data-wrapper-content smaller">
            <header class="data-wrapper-title">
              <SfHeading type="h2">
                {{ $t('Skontaktuj się z nami') }}
              </SfHeading>
            </header>
            <div class="data-wrapper-contact-flex ">
              <a v-if="main.phone" :href="`tel:${main.phone}`" class="data-wrapper-link">
                <IconPhone />
                <span v-html="main.phone" />
              </a>
              <a v-if="main.email" :href="`mailto:${main.email}`" class="data-wrapper-link">
                <IconEnvelope />
                <span v-html="main.email" />
              </a>
            </div>
          </section>
          <section class="data-wrapper-content">
            <header class="data-wrapper-title">
              <SfHeading type="h2">
                {{ $t('Adres') }}
              </SfHeading>
            </header>
            <div>
              <a :href="main.maps || '#'" :title="$t('Zobacz na mapach')" class="data-wrapper-address" rel="noreferrer nofollow noopener" target="_blank">
                <span v-if="main.name" v-text="main.name" />
                <span v-if="main.first_line" v-text="main.first_line" />
                <span v-if="main.second_line" v-text="main.second_line" />
                <span v-if="main.third_line" v-text="main.third_line" />
              </a>
              <div>
                <h3 class="data-wrapper-hours hours-title">
                  {{ $t('Godziny otwarcia') + ':' }}
                </h3>
                <template v-for="day in ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']">
                  <div v-if="main.hours[day]" class="data-wrapper-hours">
                    <span><b>{{ days[day] }}</b></span>
                    <span v-if="main.hours[day].closed">{{ $t('zamknięte') }}</span>
                    <span v-else>{{ main.hours[day].from }} - {{ main.hours[day].to }}</span>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </div>
      </section>
      <NewsletterAndAction :offer="true" class="contact-newsletter" />
      <SectionRenderer :sections="sections" placement="after" />
    </div>
  </main>
</template>
<style lang="scss" scoped>
  .contact-wrapper {

    .contact-newsletter {
      margin: 2rem 0;
    }

    .contact-wrapper-content {
      display: grid;
      grid-template-columns: 55% 1fr;
      gap: 60px;
      margin: 2rem 0;

      .form-wrapper,
      .data-wrapper {
        background-color: var(--color-white);
        border-radius: 5px;
        line-height: 1.4rem;
        font-size: 18px;
      }

      .form-wrapper {
        padding: 60px;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        header {
          text-align: center;

          .contact-description {
            margin-top: 30px;
          }
        }

        .form {
          margin-top: 65px;

          .form-input-container {
            display: flex;
            flex-direction: column;
            gap: 25px;
          }

          .form-button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
          }
        }
      }

      .data-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 65px;
        line-height: 1.4rem;
        font-size: 18px;
        padding: 60px;

        h2 {
          font-size: 18px;
          font-weight: 500;
        }

        .data-wrapper-content {
          border: 1px solid var(--color-grey);
          border-radius: 10px;
          position: relative;
          width: 100%;
          text-align-last: left;
          padding: 50px;
          font-size: 20px;
          line-height: 27px;
          font-weight: 600;
        }

        .data-wrapper-title {
          position: absolute;
          top: 0;
          left: 20px;
          transform: translateY(-50%);
          background-color: var(--color-white);
          padding: 2px 10px 2px 20px;

          .section-title::after {
            background-color: var(--color-grey);
          }
        }

        .data-wrapper-link {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;
          color: var(--color-black);
          transition: var(--primary-transition);

          &:hover {
            color: var(--primary-color);
          }

          svg {
            font-size: 27px;
            color: var(--primary-color);
          }

          .icon-mail {
            font-size: 22px;
          }
        }

        .data-wrapper-contact-flex {
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
          line-height: 27px;
          font-size: 20px;
        }

        .data-wrapper-address {
          display: block;
          font-size: 18px;
          margin-bottom: 30px;
          line-height: 19px;
          color: var(--color-black);
          transition: var(--primary-transition);

          &:hover {
            color: var(--primary-color);
          }

          span {
            display: block;
          }
        }

        .data-wrapper-hours {
          font-weight: normal;
          line-height: 24px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 16px;

          b {
            font-size: 14px;
          }
        }

        .hours-title {
          margin-bottom: 5px;
        }
      }
    }
  }

  @media(max-width: 1400px) {
    .contact-wrapper .contact-wrapper-content {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 30px;

      .form-wrapper {
        padding: 60px 0;
      }
    }
  }

  @media (max-width: 992px) {
    .contact-wrapper .contact-wrapper-content {
      grid-template-columns: 1fr;
      width: 100%;
      gap: 30px;
      margin-bottom: 30px;

      .form-wrapper {
        header .contact-description {
          font-size: 14px;
          margin-top: 20px;
        }

        .form {
          margin-top: 40px;
        }
      }

      .form-wrapper,
      .data-wrapper {
        width: 100%;
        padding: 2rem 1rem;

        .data-wrapper-content {
          padding: 50px 0 55px 30px;

          &.smaller {
            padding: 35px 0 30px 25px;
          }
        }
      }

      .data-wrapper {
        gap: 2rem;
      }
    }
  }
</style>
