<script setup lang="ts">
  import { computed, type PropType } from 'vue';

  type OrderSummary = {
    currency_code: string;
    currency_decimals: number;
    discount_gross: string;
    items_gross: string;
    loyalty_value: number;
    shipping_option_service: string | undefined;
    shipping_cost: number;
    total_gross: number;
  }

  const props = defineProps({
    order: { type: Object as PropType<OrderSummary>, required: true },
  });

  const code = computed(() => props.order.currency_code);
  const decimals = computed(() => props.order.currency_decimals);

  const items = computed(() => Number(props.order.items_gross));
  const discount = computed(() => Number(props.order.discount_gross));
  const points = computed(() => Number(props.order.loyalty_value));
  const shipping = computed(() => Number(props.order.shipping_cost));
  const total = computed(() => Number(props.order.total_gross));
</script>
<template>
  <div class="order-cart-summary">
    <dl class="order-cart-summary__wrapper">
      <div class="order-cart-summary__item order-cart-summary__products">
        <dt class="order-cart-summary__item-term">
          {{ $t('Cena produktów') + ':' }}
        </dt>
        <dd class="order-cart-summary__item-value">
          <SfMoney :value="items" v-bind="{ code, decimals }" />
        </dd>
      </div>
      <div v-if="order.shipping_option_service" class="order-cart-summary__item delivery" :class="{ 'order-cart-summary__item--discount': shipping <= 0 }">
        <dt class="order-cart-summary__item-term">
          {{ $t('Dostawa') + ':' }}
        </dt>
        <dd class="order-cart-summary__item-value">
          <SfMoney :value="shipping" v-bind="{ code, decimals }" />
        </dd>
      </div>
      <div v-if="discount > 0" class="order-cart-summary__item order-cart-summary__item--discount">
        <dt class="order-cart-summary__item-term">
          {{ $t('Rabat') + ':' }}
        </dt>
        <dd class="order-cart-summary__item-value">
          <SfMoney :value="discount * -1" v-bind="{ code, decimals }" />
        </dd>
      </div>
      <div v-if="points > 0" class="order-cart-summary__item order-cart-summary__item--discount">
        <dt class="order-cart-summary__item-term">
          {{ $t('Punkty lojalnościowe') + ':' }}
        </dt>
        <dd class="order-cart-summary__item-value">
          <SfMoney :value="points * -1" v-bind="{ code, decimals }" />
        </dd>
      </div>
      <div class="order-cart-summary__item order-cart-summary__final">
        <dt class="order-cart-summary__item-term">
          {{ $t('Łączna kwota') + ':' }}
          <span class="order-cart-summary__final-vat">{{ $t('z VAT') }}</span>
        </dt>
        <dd class="order-cart-summary__item-value">
          <SfMoney :value="total" v-bind="{ code, decimals }" />
        </dd>
      </div>
    </dl>
    <slot />
  </div>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-cart-summary-border-color: var(--secondary-border);
    --sf-order-cart-summary-vat-color: var(--secondary-color);
    --sf-order-cart-summary-discount-color: var(--sf-status-color-success);
  }

  .order-cart-summary {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      line-height: 1.25rem;
    }

    &__final {
      padding: 1rem 0;
      border-top: 1px solid var(--sf-order-cart-summary-border-color);
      font-weight: var(--font-weight-bold);
      font-size: 1.25rem;
      line-height: 1;
    }

    &__final-vat {
      display: block;
      color: var(--sf-order-cart-summary-vat-color);
      font-size: 1rem;
      font-weight: var(--font-weight-regular);
      margin-top: 2px;
    }

    &__item-value {
      font-weight: var(--font-weight-bold);
    }

    &__item--discount {
      color: var(--sf-order-cart-summary-discount-color);
    }
  }

  @include media.query(lg) {

    .order-cart-summary {
      &__wrapper {
        margin-bottom: 0;
        gap: 0.375rem;
      }

      &__item {
        font-size: 0.875rem;
        line-height: 1rem;
      }

      &__final {
        padding-top: 0.875rem;
        padding-bottom: 1rem;
        font-size: 1rem;
      }
    }
  }
</style>
