<script setup lang="ts">
  import { useQuery } from '@plenny/visitor';

  defineProps({
    category: { type: Object, required: true },
  });

  const query = useQuery();
</script>
<template>
  <article class="category-card">
    <SfTile :href="url(category.url, query)" class="category-card__link" interactive small>
      <div class="category-card__photo">
        <MediaPicture :photo="category.photo" sizes="560x390 2x, 260x195" />
      </div>
      <h2 class="category-card__heading">
        {{ category.name }}
      </h2>
      <span class="category-card__count" :aria-label="$t('Ilość produktów')">
        ({{ category.products }})
      </span>
    </SfTile>
  </article>
</template>
<style scoped lang="scss">
  .category-card {
    display: flex;

    &__heading {
      font-size: 1rem;
    }

    &__link {
      flex-grow: 1;
      text-align: center;
      justify-content: center;
    }

    &__count {
      align-items: center;
      color: var(--color-grey);
      font-size: 0.875rem;
    }

    :deep(.tile__content) {
      flex-grow: 0;
    }
  }

  @media(max-width: 992px) {
    .category-card {
      --sf-tile-small-gap: 0.5rem;
    }
  }
</style>
