<script lang="ts" setup>
  import { onUnmounted, computed, resolveComponent } from 'vue';
  import { useQuery, useSession, $redirect, route } from '@plenny/visitor';
  import { useTimer } from '$storefront';
  import { CartLayout } from '$components';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  defineProps({
    order: { type: Object, required: true },
    payment: { type: Object, required: true },
    method: { type: Object, required: true },
  });

  const session = useSession();
  const query = useQuery();

  const isAuthenticated = computed(() => {
    return session.value.is_authenticated;
  });

  const { formatted, stopTimer } = useTimer({
    timestamp: query.value.expires,
    callback: () => {
      $redirect(route('web.homepage.index'));
    },
  });

  const component = {
    PayU: null, // There is no more details to show for these drivers.
    Przelewy24: null, // There is no more details to show for these drivers.
    BankTransfer: resolveComponent('OrderPaymentSuccessBankTransfer'),
    CashOnDelivery: null, // There is no more details to show for these drivers.
  };

  onUnmounted(() => {
    stopTimer();
  });
</script>
<template>
  <div class="order">
    <div class="order-success">
      <SfTile class="order-success__confirmation">
        <div class="order-success__icon-wrapper">
          <SfIconCheck />
        </div>
        <main class="order-success__content">
          <article class="order-success__header">
            <header class="order-success__heading-wrapper">
              <h1 class="order-success__heading">{{ $t('Dziękujemy, twoje zamówienie zostało przyjęte!') }}</h1>
            </header>
            <section class="order-success__message-wrapper">
              <p class="order-success__message">{{ $t('Na twój adres e-mail wysłaliśmy potwierdzenie zamówienia, wraz z linkiem do szczegółów zamówienia.') }}</p>
              <p v-if="isAuthenticated" class="order-success__account-info">{{ $t('Zamówienie znajdziesz też w zakładce Moje Konto.') }}</p>
            </section>
          </article>

          <component :is="component[method.driver]" v-bind="{ order, payment, method }" />

          <article class="order-success__order">
            <section class="order-success__order-section">
              <p class="order-success__order-id">{{ $t('Numer twojego zamówienia: ') }}<strong>{{ order.id }}</strong></p>
            </section>
          </article>

          <nav class="order-success__navigation">
            <SfButton :href="route('web.homepage.index')" class="redirect" large>
              {{ $t('Strona główna') }}
            </SfButton>
          </nav>

          <article class="order-success__security">
            <p class="order-success__security-text">{{ $t('Ze względów bezpieczeństwa ta&nbsp;strona wygasa&nbsp;za: ') }}<b>{{ formatted }}</b></p>
          </article>
        </main>
      </SfTile>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .icon-check-marker {
    fill: var(--sf-order-success-icon-color-marker);
    stroke: var(--sf-order-success-icon-color-marker);
  }

  .icon-check-border {
    fill: var(--sf-order-success-icon-color);
    stroke: var(--sf-order-success-icon-color);
  }

  .order-success {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    &__confirmation {
      position: relative;
      width: 100%;
      max-width: 700px;
      margin-top: 3rem;
    }

    &__icon-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      height: 100px;
      width: 100px;
      font-size: 3rem;
      color: var(--sf-order-success-icon-color);
      border-radius: 50%;
    }

    &__content,
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__content {
      gap: 1.5rem;
    }

    &__header {
      gap: 1rem;
    }

    &__heading {
      text-align: center;
      margin: 3rem 0 2rem;
    }

    &__order {
      font-size: 1.25rem;
    }

    &__navigation {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 1rem 0;
    }

    &__security {
      font-size: 0.75rem;
    }
  }

  @include media.query(lg) {
    .order-success {
      margin: 0;
      padding: 1rem;

      &__icon-wrapper {
        width: 70px;
        height: 70px;

        svg {
          font-size: 2rem;
        }
      }

      &__confirmation {
        border-radius: var(--sf-tile-border-radius, 0);
        box-shadow: var(--sf-tile-interactive-box-shadow);
        padding: 1rem 0.5rem;
      }

      &__header {
        text-align: center;
        margin-top: 1rem;
      }

      &__order {
        text-align: center;
      }

      &__heading {
        font-size: 1.5rem;
        margin: 1rem 0;
      }
    }

    @include media.query(md) {
      .order-success__security {
        text-align: center;
      }
    }
  }
</style>
