<script lang="ts" setup>
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    products: Array,
  });

  const parameters = ref({
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });
</script>
<template>
  <Section :link="route('web.product.index')" :title="header">
    <SfProductsCarousel :parameters :products="products" />
  </Section>
</template>

<style lang="scss" scoped>
  @media(max-width: 576px) {
    .section-wrapper {
      padding-right: 0;
    }
  }
</style>
