<script setup lang="ts">
  import { ref } from 'vue';
  import { $patch } from '@plenny/visitor';
  import { CartLayout } from '$components';

  defineOptions({
    layout: CartLayout,
    inheritAttrs: false,
  });

  const props = defineProps({
    order: { type: Object, required: true },
    repayment: { type: String, required: true },
  });

  const order = ref(props.order);

  function onSubmit(data) {
    return $patch(props.repayment, data);
  }
</script>
<template>
  <VisitorForm @submit="onSubmit" :raw="order" v-slot="{ submitting }">
    <div class="order">
      <div class="order-payment">
        <div class="order-payment__column order-payment__column--left">
          <h1 class="order-payment__heading">
            {{ $t('Opłać zamówienie') }}
          </h1>
          <SfTile numeric id="billing-method" class="order-payment__tile order-payment__billing-method" v-if="order.total_gross != 0">
            <template #outer>
              <div class="order-payment__section-header">
                <span class="order-payment__number" />
                <h2 class="order-heading">{{ $t('Sposób płatności') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormBillingMethod />
            </template>
          </SfTile>
          <SfTile numeric id="order-regulation" class="order-payment__tile order-payment__conditions">
            <template #outer>
              <div class="order-payment__section-header">
                <span class="order-payment__number" />
                <h2 class="order-heading">{{ $t('Regulamin') }}</h2>
              </div>
            </template>
            <template #default>
              <OrderFormConditions />
            </template>
          </SfTile>
        </div>
        <div class="order-payment__column order-payment__column--right">
          <SfTile class="order-payment__summary" number>
            <template #outer>
              <h2 class="order-heading">{{ $t('Twoje zamówienie') }}</h2>
            </template>
            <div class="order-payment__products">
              <OrderSummaryItems v-for="item in order.items" :key="item.id" :item="item" />
            </div>
            <div class="order-payment__options-summary">
              <OrderSummaryShippingOption class="order-payment__option-summary" />
              <OrderSummaryBillingOption class="order-payment__option-summary" />
              <OrderSummaryRealizationTime class="order-payment__option-summary" />
            </div>
            <OrderSummary :order="order">
              <SfButton type="submit" primary :loading="submitting">
                {{ $t('Opłać zamówienie') }}
              </SfButton>
            </OrderSummary>
          </SfTile>
        </div>
      </div>
    </div>
  </VisitorForm>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .order-payment {
    display: grid;
    grid-template-columns: 2fr minmax(450px, 1fr);
    gap: 3rem;

    &__section-header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__number {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1.5rem;
      font-weight: var(--font-weight-bold);
      line-height: 1;
      padding: 0.375rem;
      border-radius: 50%;
      border-style: var(--sf-order-payment-number-border-style);
      border-color: var(--sf-order-payment-number-border-color);
      border-width: var(--sf-order-payment-number-border-width);
      counter-increment: list;

      &::after {
        content: counter(list)
      }
    }

    &__column--left {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      counter-reset: list;
    }

    &__summary {
      --sf-tile-gap: 2rem;
      position: sticky;
      top: var(--sf-order-payment-summary-sticky-top);
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }

    &__options-summary {
      display: flex;
      flex-direction: column;
      border-radius: var(--sf-order-payment-options-summary-border-radius);
      background-color: var(--sf-order-payment-options-summary-bg);
    }

    &__option-summary {
      &:first-child {
        padding-top: 1rem;
      }

      &:last-child {
        padding-bottom: 1rem;
      }
    }
  }

  @include media.query(xl) {
    .order-payment {
      grid-template-columns: 1fr;
      margin: 0;
      gap: 10px;

      &__column--right {
        margin-top: 2rem;
      }

      &__heading {
        margin-top: 20px;
      }
    }
  }

  @include media.query(lg) {
    .order-payment {
      margin: 0;
      gap: 10px;

      .order-heading {
        font-size: 1.25rem;
      }

      &__tile {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      &__number {
        height: 2rem;
        width: 2rem;
        font-size: 1.25rem;
      }

      &__column--left {
        gap: 1rem;
      }

      &__heading {
        font-size: 25px;
        margin-top: 0;
        padding-left: 10px;
      }

      &__summary {
        gap: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

      }
    }
  }
</style>
