<script lang="ts" setup>
  import { useBreakpoints, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route, $post, useShared, $delete } from '@plenny/visitor';
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
    },
  });

  const shared = useShared();
  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const breakpoints = useBreakpoints();

  const isCompared = computed(() => {
    if (props.product.variant_id && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.product.variant_id);
    } else {
      return false;
    }
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.product.variant_id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.product.variant_id }));
    }
  }

  const compareTitle = computed(() => {
    return isCompared.value ? trans('Usuń z porównania') : trans('Dodaj do porównania');
  });

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });
</script>
<template>
  <div :class="{ 'product--horizontal': horizontal }" class="product" @click="$redirect(target)">
    <div class="product__wrapper">
      <div class="product__image-wrapper">
        <MediaPicture :photo="product.photo" sizes="490x368 2x, 245x184" />
      </div>
      <div class="product__content">
        <div class="product__info">
          <header>
            <component :is="header" class="product__title">
              <VisitorLink :href="target">
                {{ product.title }}
              </VisitorLink>
            </component>
          </header>
          <dl v-if="hasListAttributes" v-show="horizontal" class="product__details">
            <div v-for="attribute in product.list_attributes" :key="attribute.name" class="product__details-column">
              <dt class="product__property">{{ attribute.name }}:</dt>
              <dd :title="attribute.value" class="product__value">{{ attribute.value }}</dd>
            </div>
          </dl>
        </div>
        <div v-if="canCustomerOrder" class="product__bottom">
          <div class="product__add-item-wrapper">
            <div class="price__price">
              <SfProductPrice :price="product" />
            </div>
            <SfCartButton :product="product">
              <SfButton primary :square="!horizontal || horizontal && !breakpoints.lg">
                <template v-if="horizontal && breakpoints.lg" #default>
                  {{ $t('Dodaj do koszyka') }}
                </template>
                <template #after>
                  <SfIconCartAdd />
                </template>
              </SfButton>
            </SfCartButton>
          </div>
          <SfProductOmnibus :price="product" />
        </div>
        <div v-else class="product__bottom">
          <span v-if="isCustomerAccepted === undefined" class="product__bottom__guest" @click.stop="void 0">
            <VisitorLink :href="route('web.account.session.create')" rel="nofollow">
              {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
            </VisitorLink>
          </span>
          <span v-else class="product__bottom__guest">
            {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
          </span>
        </div>
      </div>
    </div>
    <div class="product__labels">
      <SfProductTag :visible="product.is_new" tag-type="novelty" icon>
        {{ $t('Nowość') }}
      </SfProductTag>
      <SfProductTag :visible="product.price_gross !== product.regular_gross" tag-type="discount" icon>
        {{ $t('Promocja') }}
      </SfProductTag>
    </div>
    <div class="product__icons">
      <slot name="icons">
        <SfWishlistButton :variant="product.variant_id" class="product__wishlist" />
        <SfButton :class="{'compared': isCompared}" :title="compareTitle" :variant="product.variant_id" class="product-card__compare" square tabindex="0" transparent @click.stop="toggleComparator">
          <SfIconScale />
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  @use '$assets/mixins/media';

  .product {
    display: flex;
    position: relative;
    flex-grow: 1;
    border-radius: 4px;
    border: 1px solid var(--secondary-border);
    transition: var(--primary-transition);
    cursor: pointer;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding: 1rem;
      width: 100%;
    }

    &__image-wrapper {
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__title {
      width: 100%;
      margin: 1rem 0;
      font-size: 1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &__icons {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      opacity: 1;
      transition: var(--primary-transition);
    }

    &__wishlist {
      --sf-btn-padding-y-sm: 0.625rem
    }

    &__labels {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.375rem;

      @include media.query(sm) {
        flex-direction: column;
        width: auto;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &__guest {
        color: var(--secondary-color);
        font-size: 0.625rem;
      }
    }

    &__add-item-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__details {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.125rem;
      font-size: 0.75rem;

      @media (max-width: 576px) {
        column-gap: 0.625rem;
      }
    }

    &__details-column {
      display: flex;
      gap: 0.25rem;
    }

    &__value {
      display: -webkit-box;
      font-weight: var(--font-weight-semi);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    &--horizontal {

      .product__content {
        gap: 1rem;
      }
    }

    //@media(max-width: 992px) {
    //  &__details {
    //    font-size: 0.75rem;
    //    gap: 0.125rem;
    //  }
    //}

    //@media(max-width: 1400px) {
    //  &--horizontal {
    //    > .product__wrapper > .product__content > .product__info > .product__details {
    //    }
    //  }
    //}

    @media(min-width: 576px) {
      &--horizontal {

        > .product__wrapper {
          flex-direction: row;
          align-items: flex-start;
          gap: 1rem;

          > .product__image-wrapper {
            width: unset;
            flex: 0 0 175px;
          }

          > .product__content {
            justify-content: space-between;
            height: 100%;

            > .product__info {
              > header > .product__title {
                margin-top: 0;
              }
            }
          }
        }

        > .product__icons {
          flex-direction: row;
        }
      }
    }

    @media(hover: hover) {
      &:hover {
        box-shadow: var(--primary-box-shadow-100)
      }
    }
  }
</style>
