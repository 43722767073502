<script lang="ts" setup>
  import { ref, type PropType, onUpdated, onMounted, type Ref, onBeforeUpdate, onBeforeUnmount } from 'vue';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/autoplay';
  import 'swiper/css/pagination';
  import type { Photo } from '@plenny/picture';
  import type { NavButtons } from '$types';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { type: String },
    header_type: { type: String },
    content: { type: String },
    photos: { type: Array as PropType<Photo[]>, required: true },
  });

  const htmlNavigation = ref() as Ref<NavButtons>;
  const htmlElement = ref() as Ref<HTMLElement>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      loop: true,
      pagination: true,
      breakpoints: {
        1: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <section class="photo-slider-block">
    <header class="photo-slider-block__header">
      <SfHeading large :type="header_type || 'h2'" class="photo-slider-block__heading">
        {{ header }}
      </SfHeading>
      <div class="section-typography" v-html="content" />
    </header>
    <div class="items-carousel">
      <div ref="htmlElement" class="swiper photo-slider-block__swiper">
        <div class="swiper-wrapper">
          <div v-for="photo in photos" class="swiper-slide">
            <MediaPicture :photo type="photo" />
          </div>
        </div>
        <div class="swiper-pagination" />
      </div>
      <SfCarouselButtons ref="htmlNavigation" />
    </div>
  </section>
</template>
<style lang="scss" scoped>
  @use "$assets/mixins/media";

  .photo-slider-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .items-carousel {
      width: 100%;
    }
  }

  @include media.query(lg) {
    .photo-slider-block {
      padding: 0 1rem;
      gap: 1rem;

      &__header {
        gap: 0.5rem;
      }
    }
  }
</style>