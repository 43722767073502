<script setup lang="ts">
  import Section from '../../../Components/products/Section.vue';
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    products: Array,
    photos: Array,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 3,
      },
    },
  });
</script>

<template>
  <div class="wrapper sale-wrapper">
    <div class="baner-container">
      <MediaPicture :photo="photos" type="banner" />
    </div>
    <Section class="sale-container" :title="header" :link="route('web.product.index')">
      <SfProductsCarousel :products="products" :parameters />
    </Section>
  </div>
</template>

<style scoped lang="scss">
  .sale-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .section-wrapper {
      margin: 0;
    }

    .baner-container {
      width: calc(40% - 15px);
      max-width: 625px;

      & :deep(img) {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .sale-container {
      width: calc(60% - 15px);
    }
  }

  @media (max-width: 992px) {

    .sale-wrapper {
      flex-direction: column;
      gap: 15px;

      .baner-container {
        width: 100%;
        max-width: 100%;
        padding: 0 15px;
        height: 300px;
      }

      .sale-container {
        width: 100%;
      }
    }

  }

  @media(max-width: 576px) {
    .section-wrapper {
      padding-right: 0;
    }
  }

</style>
