<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
  });
</script>
<template>
  <div class="wrapper section-wrapper">
    <div class="section-header">
      <SfHeading v-if="title" type="h2">
        {{ title }}
      </SfHeading>
      <SfButton v-if="link" transparent class="btn-custom" :href="link" :small="!breakpoints.md">
        <template #default>
          {{ $t('Zobacz więcej') }}
        </template>
        <template #after>
          <IconChevronRight/>
        </template>
      </SfButton>
    </div>
    <div class="section-content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .section-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 30px;
    width: 100%;
    border-radius: 5px;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0.75rem;
      width: 100%;

      :deep(.section-title) {
        font-size: 1.125rem;
      }

      .btn-custom {
        --sf-btn-font-weight: 400;

        svg {
          font-size: 0.875rem;
        }
      }
    }

    .section-content {
      margin-top: 40px;
    }
  }

  @media(max-width: 992px) {
    .section-wrapper {
      padding: 15px 10px;

      .section-content {
        margin-top: 15px;
      }
    }
  }
</style>
