<script setup>
  const props = defineProps({
    title: {
      type: String,
      required: false,
    },
  });

</script>
<template>
  <div class="frame-wrapper">
    <div v-if="title" class="title">
      <SfHeading>{{ props.title }}</SfHeading>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .frame-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    row-gap: 10px;
    padding: 20px;
    border: 1px solid var(--color-grey);
    border-radius: 5px;
    height: fit-content;

    .title {
      position: absolute;
      top: 0;
      left: 20px;
      line-height: 18px;
      transform: translateY(-50%);
      background-color: var(--color-white);
      padding: 5px 8px;

      .section-title {
        font-weight: 600;

        &::after {
          content: none;
        }
      }
    }
  }
</style>
