<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data).then(() => {
      signUpEmail.value = true;
    });
  }

  const subscribe = computed(() => {
    return trans('Subskrybuj nasz newsletter i odbieraj od nas <span class="newsletter__text--green">KODY RABATOWE</span>.');
  });

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <div class="newsletter">
    <SfHeading larger type="h2">
      {{ $t('Newsletter') }}
    </SfHeading>
    <template v-if="!signUpEmail">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
        <p class="newsletter__text" v-html="subscribe" />
        <div class="newsletter__content">
          <SfFormInput :label="$t('E-mail')" name="email" required />
          <SfButton :loading="submitting" type="submit">
            {{ $t('Zapisz się') }}
          </SfButton>
        </div>
        <div class="newsletter__consent">
          <SfFormSwitch name="acceptance_rodo" required>
            <span v-html="privacyPolicy" />
          </SfFormSwitch>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </div>
</template>
<style lang="scss">
  .newsletter {
    padding: 60px 70px 50px 100px;
    background-color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    &__text {
      font-weight: 600;
      font-size: 1.125rem;
      text-align: center;

      &--green {
        color: var(--primary-color);
        font-weight: 700;
      }
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 2rem;
    }

    &__consent {
      --sf-checkbox-label-font-size: 0.75rem;
      --sf-checkbox-label-color: var(--color-gray);

      a {
        text-decoration: underline;
        transition: color 0.2s ease-out;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .subscribed {
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    .newsletter {
      padding: 60px 0 0 0;
      gap: 1rem;

      &__text {
        //margin-top: 1rem;
        font-size: 15px;
        line-height: 1.25rem;
      }

      &__content {
        gap: 0.625rem;
      }

      &__consent {
        width: 100%;
      }
    }
  }

  @media (max-width: 1400px) {
    .newsletter {
      padding: 2rem;
    }
  }

  @media (max-width: 1200px) {
    .newsletter {
      .newsletter__content {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }

  @media (max-width: 992px) {
    .newsletter {
      padding: 0;
    }
  }
</style>

