<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { route, $get, $patch, $delete } from '@plenny/visitor';
  import { trans } from '@plenny/translator';
  import { useCart } from '$storefront';

  type LoyaltyPoints = {
    is_active: Boolean,
    points: number,
    min_points_for_order: number,
    points_value: number,
  }

  const emit = defineEmits({
    toggle: (section: string) => void 0,
  });

  defineProps({
    open: { type: Boolean, default: false },
  });

  const { cart } = useCart();
  const loyalty = ref<LoyaltyPoints>();

  onMounted(() => {
    $get(route('api.v1.web.cart.loyalty.show')).then((res) => {
      loyalty.value = res;
    });
  });

  function onSubmit(data: number | null) {
    return $patch(route('api.v1.web.cart.loyalty.update'), data);
  }

  function onDestroy() {
    if (confirm(trans('Czy na pewno chcesz usunąć rabat z punktów lojalnościowych ze swojego koszyka?'))) {
      $delete(route('api.v1.web.cart.loyalty.destroy'));
    }
  }
</script>
<template>
  <article class="order-loyalty-points">
    <template v-if="cart.loyalty_points === 0">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit" class="order-loyalty-points__form">
        <div
          class="order-loyalty-points__header"
          role="button"
          tabindex="0"
          aria-controls="order-loyalty-points__apply"
          :aria-pressed="open"
          @click="$emit('toggle', 'loyalty')"
          @keydown.space="$emit('toggle', 'loyalty')"
        >
          <h3 class="order-loyalty-points__heading">{{ $t('Wykorzystaj punkty lojalnościowe') }}</h3>
          <SfIconPlus :class="{ 'order-loyalty-points__icon--open': open }" aria-hidden="true" class="order-loyalty-points__icon order-loyalty-points__icon--apply" />
        </div>
        <div v-if="loyalty" id="order-loyalty-points__apply" class="order-loyalty-points__content" :class="{ 'order-loyalty-points__content--open': open }" :inert="!open">
          <div class="order-loyalty-points__content-outer">
            <div class="order-loyalty-points__total">
              <p class="order-loyalty-points__remaining">
                <span class="order-loyalty-points__total-label">
                  {{ $t('Punkty do wykorzystania') + ': ' }}
                </span>
                <span class="order-loyalty-points__total-value">
                  {{ loyalty.points }}
                </span>
              </p>
              <p class="order-loyalty-points__value">
                <span class="order-loyalty-points__value-label">
                  {{ $t('Wartość po przeliczeniu') + ': ' }}
                </span>
                <span class="order-loyalty-points__value-wrapper">
                  <SfMoney :value="loyalty.points_value" :code="cart.currency_code" class="order-loyalty-points__money" />
                </span>
              </p>
            </div>
            <div class="order-loyalty-points__content-inner">
              <SfFormNumber :min="loyalty.min_points_for_order" :label="$t('Punkty')" :floating="false" name="points" step="1" class="order-loyalty-points__input" />
              <SfButton :loading="submitting" primary type="submit" class="order-loyalty-points__add-button">
                {{ $t('Dodaj') }}
              </SfButton>
            </div>
          </div>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <article id="order-loyalty-points__applied">
        <header class="order-loyalty-points__applied-header">
          <h3 class="order-loyalty-points__applied-heading">{{ $t('Zastosowane punkty') + ':' }}</h3>
          <p class="order-loyalty-points__applied-value">{{ cart.loyalty_points }}</p>
        </header>
        <section class="order-loyalty-points__applied-section">
          <SfDiscount :value="cart.loyalty_value" type="FIXED" class="order-loyalty-points__discount" />
          <SfButton small danger transparent @click="onDestroy" class="order-loyalty-points__delete">
            <template #before>
              <SfIconTrash class="order-loyalty-points__icon order-loyalty-points__icon--delete" role="button" />
            </template>
            <template #default>
              {{ $t('Usuń punkty') }}
            </template>
          </SfButton>
        </section>
      </article>
    </template>
  </article>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  :root {
    --sf-order-loyalty-transition-timing-func: ease-in-out;
    --sf-order-loyalty-transition-duration: 200ms;

    --sf-order-loyalty-border-width: 1px;
    --sf-order-loyalty-border-style: solid;
    --sf-order-loyalty-border-color: var(--secondary-color);
  }

  .order-loyalty-points {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      cursor: pointer;

    }

    &__heading {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: var(--font-weight-regular);
    }

    &__icon {
      &--apply {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
        font-size: 1.25rem;
        line-height: 1;
        transition: transform;
        transition-duration: var(--sf-order-loyalty-transition-duration);
        transition-timing-function: var(--sf-order-loyalty-transition-timing-func);
      }

      &--open {
        transform: rotate(45deg);
      }
    }

    &__content {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows;
      transition-duration: var(--sf-order-loyalty-transition-duration);
      transition-timing-function: var(--sf-order-loyalty-transition-timing-func);

      &--open {
        grid-template-rows: 1fr;
      }
    }

    &__total {
      margin: 0.625rem 0;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &__total-value {
      font-weight: var(--font-weight-semi);
    }

    &__content-outer {
      overflow: hidden;
    }

    &__content-inner {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      padding: 1rem 0.375rem;
      border-bottom-color: var(--sf-order-coupon-border-color);
      border-bottom-style: var(--sf-order-coupon-border-style);
      border-bottom-width: var(--sf-order-coupon-border-width);

      .control__wrapper--number,
      .btn {
        flex-basis: 50%;
      }
    }

    &__input {
      display: inline-flex;
      flex-grow: 0;
    }

    #order-loyalty-points__applied {
      font-size: 0.875rem;
      padding-bottom: 1rem;
      border-bottom-color: var(--sf-order-coupon-border-color);
      border-bottom-style: var(--sf-order-coupon-border-style);
      border-bottom-width: var(--sf-order-coupon-border-width);
    }

    &__applied-header {
      font-weight: var(--font-weight-semi);
      margin-bottom: 0.375rem;
    }

    &__applied-heading {
      font-size: 0.875rem;
      font-weight: var(--font-weight-regular);
    }

    &__applied-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
  }

  @include media.query(lg) {
    .order-loyalty-points {
      margin-top: 0.625rem;

      &__content {
        margin-top: 0.375rem;
      }
    }
  }
</style>
